import React from "react";

import MainLayout from "../layouts/MainLayout";

import Seo from "../components/common/SEO";
import AuthWrapper from "../AuthWrapper";
import Dashboard from "../components/common/Account/Dashboard";

const collections = () => {
  return (
    <MainLayout>
      <AuthWrapper>
        <Seo title="Add your store" />
        <div className="main">
          <Dashboard />
        </div>
      </AuthWrapper>
    </MainLayout>
  );
};

export default collections;
