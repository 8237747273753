import React, { useEffect, useState } from "react";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import Modal from "react-modal";
import { LoadingOverlay, Loader } from "react-overlay-loader";

// import Custom Components
import PageHeader from "../page-header";
import Breadcrumb from "../breadcrumb";

import {
  getIsAuthenticated,
  getIsUploadingUser,
  getUploadErrors,
  getUploadStatus,
  getUser,
  logout,
  updateUser,
  uploadInit,
} from "../../../reducers/user";
import Orders from "./Orders";

Modal.setAppElement("#___gatsby");

function DashBoard({
  loggedInUser,
  isAuthenticated,
  logout,
  updateUser,
  isUploading,
  uploadErrors,
  uploadStatus,
  uploadInit,
}) {
  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState(loggedInUser.firstName);
  const [lastName, setLastName] = useState(loggedInUser.lastName);
  const [email, setEmail] = useState(loggedInUser.email);
  const [password, setPassword] = useState("");
  const [currentPassword, setcurrentPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState(loggedInUser.address?.phone);
  const [address, setAddress] = useState(loggedInUser.address?.address);
  const [city, setCity] = useState(loggedInUser.address?.city);
  const [state, setState] = useState(loggedInUser.address?.state);
  const [zip, setZip] = useState(loggedInUser.address?.zip);

  const [modalState, setModalState] = useState({ type: 0, isOpen: false });
  useEffect(() => {
    uploadInit();
  }, [uploadInit]);
  const handleSubmit = (e) => {
    e.preventDefault();
    // this.validator.allValid();
    setLoading(true);
    let isPasswordChanged = false;
    if (password) {
      isPasswordChanged = true;
    }
    const data = {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      currentPassword,
      isPasswordChanged,
      // phone,
      // address,
      // city,
      // state,
      // zip,
    };
    updateUser(data);
    setLoading(false);
  };
  const updateAddress = (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      address: {
        phone,
        address,
        city,
        state,
        zip,
      },
    };
    updateUser(data);
    setLoading(false);
  };

  const onCloseModal = () => {
    uploadInit();
    setModalState({ type: -1, isOpen: false });
  };

  return (
    <>
      <Helmet>
        <title> My Account</title>
      </Helmet>

      <LoadingOverlay>
        <Loader text="Processing ..." loading={isUploading} />

        <h1 className="d-none">My Account</h1>

        <div className="main">
          <PageHeader title="My Account" subTitle="Shop" />
          <Breadcrumb title="My Account" adClass="mb-3" />

          <div className="page-content">
            <div className="dashboard">
              <div className="container">
                {loggedInUser && isAuthenticated && (
                  <ul className="nav nav-dashboard flex-column mb-3 mb-md-0">
                    <Tabs
                      selectedIndex={tabIndex}
                      selectedTabClassName="active show"
                    >
                      <div className="row">
                        <aside aria-label="dashboard" className="col-md-2 col-lg-3">
                          <TabList>
                            <Tab className="nav-item">
                              <span
                              role={"button"}
                              tabIndex="0"
                                className="nav-link"
                                onClick={() => setTabIndex(0)}
                                onKeyPress={() => setTabIndex(0)}
                              >
                                Dashboard
                              </span>
                            </Tab>

                            <Tab className="nav-item">
                              <span
                                                            role={"button"}
                                                            tabIndex="0"
                                                              className="nav-link"
                                                              onClick={() => setTabIndex(1)}
                                                              onKeyPress={() => setTabIndex(1)}
                              >
                                Orders
                              </span>
                            </Tab>

                            <Tab className="nav-item">
                              <span
                              
                              role={"button"}
                              tabIndex="0"
                                className="nav-link"
                                onClick={() => setTabIndex(2)}
                                onKeyPress={() => setTabIndex(2)}
                              >
                                Addresses
                              </span>
                            </Tab>

                            <Tab className="nav-item">
                              <span
                              
                              role={"button"}
                              tabIndex="0"
                                className="nav-link"
                                onClick={() => setTabIndex(3)}
                                onKeyPress={() => setTabIndex(3)}
                              >
                                Account Details
                              </span>
                            </Tab>

                            <Tab className="nav-item">
                              <span
                               
                               role={"button"}
                               tabIndex="0"
                                 className="nav-link"
                                 onClick={() => logout()}
                                 onKeyPress={() => logout()}
                              >
                                Sign Out
                              </span>
                            </Tab>
                          </TabList>
                        </aside>

                        <div
                          className="col-md-8 col-lg-9"
                          style={{ marginTop: "1rem" }}
                        >
                          <div className="tab-pane">
                            <TabPanel>
                              <p>
                                Hello{" "}
                                <span className="font-weight-normal text-dark">
                                  {loggedInUser.firstName}
                                </span>{" "}
                                (not{" "}
                                <span className="font-weight-normal text-dark">
                                  User
                                </span>
                                ?{" "}
                                <Link
                                  className="text-primary"
                                  to="#"
                                  role={"button"}
                                  tabIndex="0"
                                  onKeyPress={() => logout()}
                                  onClick={() => logout()}
                                >
                                  Log out
                                </Link>
                                )
                                <br />
                                From your account dashboard you can view your{" "}
                                <Link
                                 to="#"
                                 onKeyPress={() => setTabIndex(1)}
                                  onClick={() => {
                                    setTabIndex(1);
                                  }}
                                  className="tab-trigger-link link-underline text-primary"
                                >
                                  recent orders
                                </Link>
                                , manage your{" "}
                                <Link
                                 to="#"
                                 onKeyPress={() => setTabIndex(2)}
                                  onClick={() => {
                                    setTabIndex(2);
                                  }}
                                  className="tab-trigger-link link-underline text-primary"
                                >
                                  shipping and billing addresses
                                </Link>
                                , and{" "}
                                 <Link
                                 to="#"
                                 onKeyPress={() => setTabIndex(3)}
                                  onClick={() => {
                                    setTabIndex(3);
                                  }}
                                  className="tab-trigger-link  text-primary"
                                >
                                  edit your password and account details
                                </Link>
                                .
                              </p>
                            </TabPanel>

                            <TabPanel>
                              <Orders user={loggedInUser} />
                              <Link
                                to={`/allcollection/`}
                                tabIndex={-1}
                                className="btn btn-primary"
                              >
                                <span>GO SHOP</span>
                                <i className="icon-long-arrow-right"></i>
                              </Link>
                            </TabPanel>

                            <TabPanel>
                              <p>
                                The following addresses will be used on the
                                checkout page by default.
                              </p>

                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="card card-dashboard">
                                    <div className="card-body">
                                      <h3 className="card-title">Address</h3>

                                      <p>
                                        {loggedInUser.firstName +
                                          " " +
                                          loggedInUser.lastName}
                                        <br />
                                        {loggedInUser.address?.address??""}
                                        <br />
                                        {loggedInUser.address?.city??""},{" "}
                                        {loggedInUser.address?.state??"" +
                                          " " +
                                          loggedInUser.address?.zip??""}
                                        <br />
                                        {loggedInUser.email}
                                        <br />
                                        {loggedInUser.phone}
                                        <br />
                                        <button
                                         onKeyPress={() => logout()}
                                          onClick={() =>
                                            setModalState({
                                              type: 0,
                                              isOpen: true,
                                            })
                                          }
                                          className="text-primary"
                                        >
                                          Edit <i className="icon-edit"></i>
                                        </button>
                                      </p>
                                      <Modal
                                        isOpen={modalState.isOpen}
                                        onRequestClose={onCloseModal}
                                        center
                                      >
                                        <form action="#">
                                          {!isUploading &&
                                            uploadStatus === "success" && (
                                              <span className="alert alert-success">
                                                Updated Successfully
                                              </span>
                                            )}
                                          {typeof uploadErrors?.errors ===
                                          "string" ? (
                                            uploadErrors?.errors
                                          ) : (
                                            <ul>
                                              {uploadStatus === "failed" &&
                                               uploadErrors?.errors &&  uploadErrors.errors?.map(
                                                  (err, i) => (
                                                    <li
                                                      className="text-danger"
                                                      key={i}
                                                      style={{
                                                        display: "list-item",
                                                      }}
                                                    >
                                                      {err.msg}
                                                    </li>
                                                  )
                                                )}
                                            </ul>
                                          )}
                                          <div className="row">
                                            <div className="col-sm-6">
                                              <label htmlFor="address">
                                                Address *
                                              </label>
                                              <input
                                                type="text"
                                                id="address"
                                                name="address"
                                                value={address}
                                                onChange={(e) =>
                                                  setAddress(e.target.value)
                                                }
                                                className="form-control"
                                                required
                                              />
                                            </div>

                                            <div className="col-sm-6">
                                              <label htmlFor="phone">
                                                Phone *
                                              </label>
                                              <input
                                                name="phone"
                                                id="phone"
                                                type="text"
                                                value={phone}
                                                onChange={(e) =>
                                                  setPhone(e.target.value)
                                                }
                                                className="form-control"
                                                required
                                              />
                                            </div>
                                          </div>

                                          {/* <label>Display Name *</label>
                                                        <input type="text" className="form-control" required />
                                                        <small className="form-text">This will be how your name will be displayed in the account section and in reviews</small> */}

                                          <label htmlFor="city">City *</label>
                                          <input
                                            id="city"
                                            type="text"
                                            value={city}
                                            onChange={(e) =>
                                              setCity(e.target.value)
                                            }
                                            className="form-control"
                                            required
                                          />

                                          <label htmlFor="state">state *</label>
                                          <input
                                            id="state"
                                            type="text"
                                            value={state}
                                            onChange={(e) =>
                                              setState(e.target.value)
                                            }
                                            className="form-control"
                                            required
                                          />
                                          <label htmlFor="zip">zip *</label>
                                          <input
                                            id="zip"
                                            type="text"
                                            value={zip}
                                            onChange={(e) =>
                                              setZip(e.target.value)
                                            }
                                            className="form-control"
                                            required
                                          />

                                          <button
                                            onClick={updateAddress}
                                            type="submit"
                                            className="btn btn-outline-primary-2"
                                          >
                                            {loading && (
                                              <div
                                                className="spinner-border text-primary"
                                                role="status"
                                              >
                                                <span className="sr-only">
                                                  Loading...
                                                </span>
                                              </div>
                                            )}{" "}
                                            <span>SAVE CHANGES</span>
                                            <i className="icon-long-arrow-right"></i>
                                          </button>
                                        </form>
                                      </Modal>
                                    </div>
                                  </div>
                                </div>

                                {/* <div className="col-lg-6">
                                  <div className="card card-dashboard">
                                    <div className="card-body">
                                      <h3 className="card-title">
                                        Shipping Address
                                      </h3>

                                      <p>
                                        You have not set up this type of address
                                        yet.
                                        <br />
                                        <Link to="#">
                                          Edit <i className="icon-edit"></i>
                                        </Link>
                                      </p>
                                    </div>
                                  </div>
                                </div> */}
                              </div>
                            </TabPanel>

                            <TabPanel>
                              <form action="#">
                                {!isUploading && uploadStatus === "success" && (
                                  <span className="alert alert-success">
                                    Updated Successfully
                                  </span>
                                )}
                                {typeof uploadErrors.errors === "string" ? (
                                  uploadErrors.errors
                                ) : (
                                  <ul>
                                    {uploadStatus === "failed" &&
                                      uploadErrors.errors && uploadErrors.errors?.map((err, i) => (
                                        <li
                                          className="text-danger"
                                          key={i}
                                          style={{ display: "list-item" }}
                                        >
                                          {err.msg}
                                        </li>
                                      ))}
                                  </ul>
                                )}
                                <div className="row">
                                  <div className="col-sm-6">
                                    <label htmlFor="firstName">
                                      First Name *
                                    </label>
                                    <input
                                      type="text"
                                      id="firstName"
                                      name="firstName"
                                      value={loggedInUser.firstName}
                                      onChange={(e) =>
                                        setFirstName(e.target.value)
                                      }
                                      className="form-control"
                                      required
                                    />
                                  </div>

                                  <div className="col-sm-6">
                                    <label htmlFor="lastName">
                                      Last Name *
                                    </label>
                                    <input
                                      name="lastName"
                                      id="lastName"
                                      type="text"
                                      value={loggedInUser.lastName}
                                      onChange={(e) =>
                                        setLastName(e.target.value)
                                      }
                                      className="form-control"
                                      required
                                    />
                                  </div>
                                </div>

                                {/* <label>Display Name *</label>
                                                        <input type="text" className="form-control" required />
                                                        <small className="form-text">This will be how your name will be displayed in the account section and in reviews</small> */}

                                <label htmlFor="email">Email address *</label>
                                <input
                                  id="email"
                                  type="email"
                                  value={loggedInUser.email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  className="form-control"
                                  required
                                />

                                <label htmlFor="password">
                                  Current password (leave blank to leave
                                  unchanged)
                                </label>
                                <input
                                  id="password"
                                  type="password"
                                  onChange={(e) => setPassword(e.target.value)}
                                  className="form-control"
                                />

                                <label htmlFor="newPassword">
                                  New password (leave blank to leave unchanged)
                                </label>
                                <input
                                  id="newPassword"
                                  type="password"
                                  onChange={(e) =>
                                    setcurrentPassword(e.target.value)
                                  }
                                  className="form-control"
                                />

                                <label htmlFor="confirmPassword">
                                  Confirm new password
                                </label>
                                <input
                                  id="confirmPassword"
                                  onChange={(e) =>
                                    setConfirmPassword(e.target.value)
                                  }
                                  type="password"
                                  className="form-control mb-2"
                                />

                                <button
                                  onClick={handleSubmit}
                                  type="submit"
                                  className="btn btn-outline-primary-2"
                                >
                                  {loading && (
                                    <div
                                      className="spinner-border text-primary"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                  )}{" "}
                                  <span>SAVE CHANGES</span>
                                  <i className="icon-long-arrow-right"></i>
                                </button>
                              </form>
                            </TabPanel>
                          </div>
                        </div>
                      </div>
                    </Tabs>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
}
const mapStateToProps = (state) => ({
  loggedInUser: getUser(state),
  isAuthenticated: getIsAuthenticated(state),
  isUploading: getIsUploadingUser(state),
  uploadStatus: getUploadStatus(state),
  uploadErrors: getUploadErrors(state),
});
const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  updateUser: (user) => dispatch(updateUser(user)),
  uploadInit: () => dispatch(uploadInit()),
});
export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(DashBoard)
);
