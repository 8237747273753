import React, { useEffect, useState } from "react";
import { axiosIns } from "../../../apihelper";

import { LoadingOverlay, Loader } from "react-overlay-loader";
import CartModal from "./CartModal";

function Orders({ user }) {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axiosIns.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;
    axiosIns.get("/marketplace/orders").then((res) => {
      setOrders(res.data);
    });
    setIsLoading(false);
  }, [user]);
  return (
    <>
      <LoadingOverlay>
        <Loader text="Processing ..." loading={isLoading} />

        <div className="card card-dashboard w-100">
          <div className="p-2 card-header text-center">
            <h4 style={{ marginTop: "5px" }}>Orders</h4>
          </div>
          <div className="card-body">
            {orders &&
              orders.map((order) => (
                <>
                  <div
                    key={order.serialNo || order.checkoutID}
                    className="row d-flex flex-row w-80  align-items-center flex-nowrap"
                    style={{ backgroundColor: "#fff", borderRadius: "5px" }}
                  >
                    <div className="p-2 d-flex flex-column order-date w-20 flex-grow-1 text-center">
                      <div>{new Date(order.createdAt).getFullYear()}</div>
                      <div>
                        <span
                          style={{
                            fontSize: "2.5rem",
                            fontWeight: "bold",
                            color: "#c02425",
                          }}
                        >
                          {new Date(order.createdAt).toLocaleDateString(
                            "en-US",
                            {
                              day: "numeric",
                            }
                          )}
                        </span>
                      </div>
                      <div>
                        {new Date(order.createdAt).toLocaleDateString("en-US", {
                          month: "short",
                        })}
                      </div>
                    </div>
                    <div className="p-2 d-flex flex-column w-35 flex-grow-1 text-center">
                      <div>
                        <span
                          style={{ fontWeight: "bold", fontSize: "1.3rem" }}
                        >
                          Order:{" "}
                        </span>
                        <span style={{ color: "#c02425" }}>
                          #{order.serialNo || order.checkoutID}
                        </span>
                      </div>
                      <div>
                        <span
                          style={{ fontSize: "1.8rem", fontWeight: "bold" }}
                        >
                          {order.customer.firstName.toUpperCase()}{" "}
                          {order.customer.lastName.toUpperCase()}
                        </span>
                      </div>
                    </div>
                    <div className="p-2 d-flex flex-column w-20 flex-grow-1 text-center">
                      <div>
                        <span
                          style={{ fontSize: "1.3rem", fontWeight: "bold" }}
                        >
                          Total :
                        </span>
                      </div>

                      <div>
                        <span
                          className="px-1"
                          style={{
                            fontWeight: "bold",
                            backgroundColor: "#c02425",
                            borderRadius: "5px",
                            color: "#fff",
                          }}
                        >
                          {" "}
                          $
                          {Number(
                            order.tax + order.shipping + order.subtotal
                          ).toFixed(2)}
                        </span>
                      </div>
                    </div>
                    <div className="p-2  d-flex flex-column w-20 flex-grow-1 text-center">
                      <div>
                        <span
                          style={{ fontSize: "1.3rem", fontWeight: "bold" }}
                        >
                          Status :
                        </span>
                      </div>
                      <div>
                        {" "}
                        <span style={{ fontWeight: "bold" }}>
                          {order.status}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center align-items-center mt-1">
                    <CartModal order={order} cart={order.cart} />
                  </div>
                  <hr style={{ borderTop: "1px solid #f7c6c6" }}></hr>
                </>
              ))}
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
}

export default Orders;
